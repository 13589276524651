import React from "react"
// import ScrollAnimation from 'react-animate-on-scroll';
import PropTypes from "prop-types"

const ImageGridItemPackage = ({ width, image, subtitle, delay, title, link, collab, collablink, price, text }) => (
  <div className={`column small-12 medium-6 large-${width} grid-item`}>
      <div className="row collapse grid-item__background" style={{ backgroundImage:`url(${image})` }}>
        <div className="column relative">
          <p className="subtitle">{ subtitle }</p>
          <p className="grid-item__title"><strong>{ title }</strong></p>
          <p className="grid-item__title">{ price }</p>
          <span dangerouslySetInnerHTML={{__html: text}}></span>
          <a href={ link } className='button grid-item__button'>Enquire now</a>
        </div>
      </div>
  </div>
)

ImageGridItemPackage.propTypes = {
  width: PropTypes.number,
  delay: PropTypes.number,
  image: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  collab: PropTypes.string,
  collablink: PropTypes.string,
}

ImageGridItemPackage.defaultProps = {
  width: 6,
  delay:0,
  image: ``,
  subtitle: ``,
  title: ``,
  link: ``,
  collab: ``,
  collablink: ``,
}

export default ImageGridItemPackage