import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Introduction from "../../components/introduction"
import HeroAlt from "../../components/hero-alt"
import Spacer from "../../components/spacer"
import TitleAndText from "../../components/title-and-text"
import ImageAndText from "../../components/image-and-text"
import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import CenteredSlider from "../../components/centered-slider"
import CenteredSlide from "../../components/centered-slide"
import WorkGrid from "../../components/work-grid"
import ImageGridItemService from "../../components/image-grid-item-service"
import ImageGridItemPackage from "../../components/image-grid-item-package"
import ContactForm from "../../components/contact-form"
import ImageGridItem from "../../components/image-grid-item"


class WordpressMaintenancePage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } >
        <SEO title="Wordpress Maintenance Bournemouth" />
        <HeroAlt 
          image="/maintenance.jpg"
          title="Website Maintenance & Updates"
          subtitle="Old Salt is your trusted partner to provide plugin, WP core and theme updates to give you peace of mind."
        />
        
        <Spacer space="4" />    

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Keeping your website secure and online</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Keeping your WordPress website up-to-date is hugely important. It may be tempting to click update on the plugins that you have installed. However, it's common for errors to arise and even website’s to go down following a plugin or WordPress core update.</p>
                <h3 className="h4">Plugin Updates</h3>
                <p>WordPress has an incredible community and a multitude of 3rd party plugins to help customise your website. Plugin developers should be updating their plugins regularly and this means you have to stay on top of keeping everything up-to-date.</p>
                <p>If left, plugin versions can become wildly out-of-date and can make the job of updating the website far more daunting further down the road.</p>
                <p>We have a number of WordPress support packages available to perform monthly updates and ensure that your WordPress website remains up-to-date. We are also able to fix any issues that arise as a result of the updates</p>
                <h3 className="h4">WordPress Core Updates</h3>
                <p>WordPress regularly updates their systems to add improvements, optimisations and also to fix security issues. It’s important to stay on top of these to ensure you have the best experience when updating your website’s content but also making sure that your website isn’t vulnerable to hacking and data leaks.</p>
                <p>We have support packages available to perform monthly checks and updates to make sure that you’re on the latest and greatest version of WordPress.</p>
                <p>&nbsp;</p>
              </div>              
            </div>
          </div>
        </div>


        <WorkGrid>
          <ImageGridItemPackage width={6} image={ '/package-bg.jpg' } subtitle="Support Package" title="Basic" price="£75/month" text="<ul><li>Plugin Updates</li><li>Theme Updates</li><li>WordPress Core Updates</li></ul>" link="/contact" />
          <ImageGridItemPackage width={6} image={ '/package-bg.jpg' } subtitle="Support Package" title="Complete" price="£125/month" text="<ul><li>Plugin Updates</li><li>Theme Updates</li><li>WordPress Core Updates</li><li>Nightly Backups</li><li>Website Audit</li></ul>" link="/contact" />
        </WorkGrid>
        

        <div className="gm-top--2 gm-bottom--2 contact-form--pullout">
          <div className="clearfix"></div>
          <div className="row align-center gm-top--4 gm-bottom--4">
            <div className="column small-12 medium-10 large-8">
              <h3>Get in touch</h3>
              <p>We're driven by bringing great ideas to life and working with great people. Get in touch to find out more.</p>
              <Spacer space="2" />
              <ContactForm pageName="website-maintenance" />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <WorkGrid>
          <ImageGridItemService width={6} image={ '/wordpress-service.jpg' } subtitle="Wordpress" title="WordPress Development" link="/services/wordpress-development-bournemouth" />
          <ImageGridItemService width={6} image={ '/security.jpg' } subtitle="Wordpress" title="WordPress Security" link="/services/wordpress-security" />
        </WorkGrid>
 
        
        <Spacer space="4" />   
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
      </Layout>
    )
  }
}

export default WordpressMaintenancePage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`